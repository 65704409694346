console.log("Cannabis Gesetz Jetzt! 0.2.1b");



document.addEventListener('DOMContentLoaded', function() {
    const toc = document.getElementById('markdown-toc');
    const toggle = document.querySelector('.toc p'); // The element that triggers the toggle

    if (!toc || !toggle) {
        return;
    }
    // Initially hide the table of contents
    toc.style.display = 'none';

    // Add click event listener to the toggle
    toggle.addEventListener('click', function() {
        if (toc.style.display === 'none' || toc.style.display === '') {
            toc.style.display = 'block';
            setTimeout(() => toc.classList.add('show'), 10); // Wait a tiny bit to ensure the display change has taken effect before adding opacity
        } else {
            toc.classList.remove('show');
            setTimeout(() => toc.style.display = 'none', 500); // Wait for the opacity transition to finish before hiding
        }
    });
});



document.addEventListener("DOMContentLoaded", function() {
    // Set the Bundesrat meeting date and the April 1st date
    const bundesratDate = new Date(2024, 2, 22, 9, 30); // Example: April 15th, 2023, at 09:00
    const aprilFirstDate = new Date(2024, 3, 1, 0, 0); // April 1st, 2023

    const bundesrattext_pre = `<a href="https://www.bundesrat.de/SharedDocs/termine/DE/plenum/2024/2024-03-22.html">Bundesratsitzung</a> in  `
    const bundesrattext_post = `<a href="https://www.bundesrat.de/DE/service/livestream/livestream-node.html">Livestream zur Bundesratssitzung (Heute 9:30) hier !</a>  `
    const april_pre = `<a href="https://www.bundestag.de/dokumente/textarchiv/2023/kw48-interview-kappert-gonther-980572">Erster April</a> in  `
    const april_post = `420 April, April!`

    function updateCountdown() {
        const now = new Date();
        updateElementCountdown(".xbisbr", bundesratDate, now, bundesrattext_pre, bundesrattext_post);
        updateElementCountdown(".xbisapril", aprilFirstDate, now, april_pre, april_post);
    }

    function updateElementCountdown(selector, futureDate, now, eventName, eventPost) {
        const diff = futureDate - now; // difference in milliseconds
        if (diff < 0) {
            document.querySelector(selector).innerHTML = eventPost;
            return;
        }
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        // document.querySelector(selector).innerHTML = `${eventName} ${days} Tage, ${hours} Stunden, ${minutes} Minuten und ${seconds} Sekunden.`;
        document.querySelector(selector).innerHTML = `${eventName} ${days} Tagen, ${hours} Stunden, ${minutes} Minuten.`;
    }

    updateCountdown();
    let seconds = false;
    if (seconds) {
        setInterval(updateCountdown, 1000);
    }
    else
    {
        setInterval(updateCountdown, 10000);
    }
});

// document.querySelectorAll('details').forEach((element) => {
//     element.querySelector('summary').addEventListener('click', (e) => {
//         // Prevent the default action to handle it with JS
//         e.preventDefault();

//         // Simple toggle for the 'open' attribute
//         if (element.hasAttribute('open')) {
//             element.removeAttribute('open');
//         } else {

//             element.setAttribute('open', 'true');
//         }
//     });
// });
